.child {
  width: 100%;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  flex: 1;
  width: 100%;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Medium.7f5202f0.ttf") format("truetype");
}

@font-face {
  font-family: Poppins Semibold;
  src: url("Poppins-SemiBold.2d1f49c6.ttf") format("truetype");
}

* {
  font-family: Poppins;
}

.ps {
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x {
  opacity: 0;
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  bottom: 0;
}

.ps__rail-y {
  opacity: 0;
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  opacity: .9;
  background-color: #eee;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  height: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  position: absolute;
  bottom: 2px;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  width: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  position: absolute;
  right: 2px;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.scrollbar-container {
  height: 100%;
  position: relative;
}

/*# sourceMappingURL=index.8a0aa09e.css.map */
