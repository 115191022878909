@font-face {
	font-family: 'Poppins';
    src: url('./assets/font/Poppins-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Poppins Semibold';
    src: url('./assets/font/Poppins-SemiBold.ttf') format('truetype');
}
* {
    font-family: 'Poppins';
}